<template>
  <layout>
    <template #secondarySidebar>
      <scheduler-nav />
    </template>

    <alert-response-warning
      v-if="state.hasError"
      :error="state.accessError"
    />

    <div
      v-if="!state.hasError"
      class="flex flex-col sm:flex-row sm:justify-between sm:items-center border-b pb-2"
    >
      <div class="flex justify-between items-center">
        <h2 class="font-bold text-xl sm:text-2xl">
          Schedules List
        </h2>
        <add-link
          :to="{ name: 'SchedulerSchedulesCreate' }"
          title="Add"
          class="sm:hidden"
        />
      </div>

      <div class="sm:flex sm:flex-row sm:items-center sm:justify-between sm:divide-x sm:space-x-4 mt-3 sm:mt-0">
        <text-input
          v-model="state.search"
          type="search"
          placeholder="Search..."
          class="w-full sm:w-64 lg:w-96"
        />
        <add-link
          :to="{ name: 'SchedulerSchedulesCreate' }"
          title="Add"
          class="hidden sm:flex"
        />
      </div>
    </div>

    <template v-if="!state.hasError">
      <div class="bg-concrete rounded-md shadow-sm mt-6 xl:mt-8">
        <div
          v-if="!state.hasError"
          class="flex flex-col"
        >
          <v-table :is-loading="state.isLoading">
            <template #header>
              <v-table-header>Scheduler Name</v-table-header>
              <v-table-header>Machines</v-table-header>
              <v-table-header>Target</v-table-header>
              <v-table-header>Times</v-table-header>
              <v-table-header>Starts/Ends</v-table-header>
              <v-table-header>Repeat</v-table-header>
              <v-table-header><span class="sr-only">Actions</span></v-table-header>
            </template>

            <v-table-row
              v-for="(schedule, index) in filteredSchedules"
              :key="index"
              class=" hover:bg-gray-50"
            >
              <v-table-col
                class="font-semibold"
                v-html="highlightInString(schedule.name, state.search)"
              />
              <v-table-col>
                <router-link
                  v-for="(machine, mindex) in schedule.machines"
                  :key="mindex"
                  :to="{ name: 'SchedulerMachinesIndex', query: { search: machine.name } }"
                  class="block hover:underline"
                >
                  <span v-html="highlightInString(machine.name, state.search)" />
                </router-link>
              </v-table-col>
              <v-table-col>
                {{
                  [
                    schedule.icemaker1_enabled && 'Ice Maker 1',
                    schedule.icemaker2_enabled && 'Ice Maker 2'
                  ].filter(obj => obj).join(', ')
                }}
              </v-table-col>
              <v-table-col>
                <div
                  v-for="(times, timesIndex) in schedule.schedule_times"
                  :key="timesIndex"
                >
                  {{ times.stop }} - {{ times.start }}
                </div>
              </v-table-col>
              <v-table-col>
                {{ schedule.active_from_human }}
                - {{ schedule.active_till_human }}
              </v-table-col>
              <v-table-col :title="schedule.cron">
                {{ schedule.cron_human }}
              </v-table-col>

              <v-table-col>
                <div
                  v-if="schedule.can_edit"
                  class="text-right space-x-2 flex justify-end items-center"
                >
                  <button
                    type="button"
                    class="w-8 h-8 flex items-center text-blumine hover:text-big-stone transition ease-out duration-150"
                    title="Edit Schedule"
                    @click.stop="goToEdit(schedule)"
                  >
                    <span class="sr-only">edit</span>
                    <span class="material-icons-outlined">create</span>
                  </button>
                  <button
                    type="button"
                    class="w-8 h-8 flex items-center text-blumine hover:text-red-600 transition ease-out duration-150 focus:outline-none"
                    title="Delete Schedule"
                    @click.stop="remove(schedule)"
                  >
                    <span class="sr-only">delete</span>
                    <span class="material-icons-outlined">delete</span>
                  </button>
                </div>
              </v-table-col>
            </v-table-row>

            <v-table-row v-if="filteredSchedules.length === 0">
              <v-table-col colspan="7">
                No Schedules
              </v-table-col>
            </v-table-row>
          </v-table>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import { reactive, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import date from '@/helpers/date.js'
import cron from '@/helpers/cron.js'
import { highlightInString } from '@/helpers/utils.js'
import Layout from '@/layouts/Default.vue'
import SchedulerNav from '@/components/schedules/Nav.vue'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import TextInput from '@/components/form/Text.vue'
import VTable from '@/components/Table.vue'
import VTableHeader from '@/components/TableHeader.vue'
import VTableRow from '@/components/TableRow.vue'
import VTableCol from '@/components/TableCol.vue'
import AddLink from '@/components/buttons/AddLink.vue'

export default {
  name: 'SchedulesIndex',

  components: {
    Layout,
    SchedulerNav,
    AlertResponseWarning,
    TextInput,
    VTable,
    VTableHeader,
    VTableRow,
    VTableCol,
    AddLink,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      hasError: false,
      accessError: null,
      isLoading: true,
      search: route.query.search || '',
      schedules: [],
      machines: [],
      selectedSchedules: [],
    })

    const getSchedules = async () => {
      state.isLoading = true

      try {
        const { data } = await http.get('user/machines')
        state.machines = data.allowed_locations

        const schedulesResponse = await Promise.all(state.machines.map((machine) => {
          return http.get(`machine/${machine.location_id}/schedule`)
            .then(({ data }) => {
              return {
                ...data,
                machine,
                location_id: machine.location_id,
              }
            })
        }))

        const groupedSchedules = schedulesResponse.reduce(
          (all, obj) => {
            for (let schedule of obj.schedule) {
              if (all[schedule.schedule_id]) {
                all[schedule.schedule_id].machines.push(obj.machine)
                if (obj.can_edit === false) {
                  all[schedule.schedule_id].can_edit = false
                }
              }
              else {
                all[schedule.schedule_id] = {
                  ...schedule,
                  machines: [obj.machine],
                  can_edit: obj.can_edit,
                  cron_human: cron.humanize(schedule.cron),
                  active_from_human: date.dateFormatFromApi(schedule.active_from),
                  active_till_human: date.dateFormatFromApi(schedule.active_till),
                  schedule_times: schedule.schedule_times.map((times) => {
                    return {
                      stop: date.timeFormat(times.stop).fromApi(),
                      start: date.timeFormat(times.start).fromApi(),
                    }
                  })
                }
              }
            }
            return all
          },
          Object.create(null)
        )
        state.schedules = Object.values(groupedSchedules)
      } catch (e) {
        state.hasError = true
        state.accessError = e
      }

      state.isLoading = false
    }

    const goToEdit = (schedule) => {
      router.push({
        name: 'SchedulerSchedulesEdit',
        params: {
          id: schedule.schedule_id
        }
      })
    }

    const remove = async (schedule) => {
      if (window.confirm(`Do you really want delete schedule ${schedule.name}?`)) {
        try {
          await http.delete(`machine/schedule/`, { data: {
            schedule_id: schedule.schedule_id,
            delete_from_machines: schedule.machines.map(machine => machine.location_id)
          }})
          state.schedules = state.schedules.filter(
            (_schedule) => _schedule.schedule_id !== schedule.schedule_id
          )
          notification.success('Success', `Schedule <b>${schedule.name}</b> has been deleted`)
        } catch (e) {
          notification.error('Error', 'Something went wrong. Please, try again.')
        }
      }
    }

    // const removeSelected = async () => {
    //   if (state.selectedSchedules.length === 0) {
    //     return
    //   }
    //   if (window.confirm(`Do you really want delete ${state.selectedSchedules.length} schedules?`)) {
    //     try {
    //       for (let schedule of state.selectedSchedules) {
    //         const machineId = parseInt(schedule.machine.location_id)
    //         await http.delete(`machine/${machineId}/schedule/${schedule.schedule_id}`)
    //         state.schedules = state.schedules.filter(
    //           (_schedule) => _schedule.schedule_id !== schedule.schedule_id
    //         )
    //       }
    //       state.selectedSchedules = []
    //       notification.success('Success', `Schedules have been deleted`)
    //     } catch (e) {
    //       notification.error('Error', 'Something went wrong. Please, try again.')
    //     }
    //   }
    // }

    const filteredSchedules = computed(() => {
      if (state.search === '') return state.schedules

      return state.schedules.filter((schedule) => {
        return schedule.name.toLowerCase().includes(state.search.toLowerCase())
          || schedule.machines.filter((machine) => {
            return (
              machine.name.toLowerCase().includes(state.search.toLowerCase())
              || String(machine.location_id) === state.search
            )
          }).length > 0
      })
    })

    getSchedules()

    return {
      filteredSchedules,
      state,
      highlightInString,
      goToEdit,
      remove,
      // removeSelected,
    }
  }
}
</script>
